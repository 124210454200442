<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="false"
          :title="reportTitle"
      />
    </div>

    <div class="my-2 px-2">
      <DateQuerySetter
          btnTitle="Go"
          @onClickGo="onClickGo"
      >
        <div v-if="!props.userId" class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="form-label col-form-label" for="team_member_id">Team Member</label>
          <div>
            <v-select
                placeholder="Select Team Member"
                v-model="member"
                :options="members"
                label="name"
                :reduce="name => name.id"
            />
          </div>
        </div>
      </DateQuerySetter>

    </div>
    <div class="col-12 px-2 mt-2">
      <ListTable
          :tableItems="workStatusReports"
      />
    </div>
    <div class="px-2">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import {ref, onMounted, computed} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import handleCompanyContact from "@/services/modules/companyContact";
import handleProjects from "@/services/modules/procurement/project";
import ListTable from "@/components/molecule/company/contact/WorkStatusReportTableList.vue";

const {fetchWorkStatusReport} = handleCompanyContact();
const {fetchParties} = handleProjects();

const props = defineProps({
  reportTitle: {
    type: String
  },
  userId: {
    default: null
  }
})
const route = useRoute()
const router = useRouter()
const store = useStore()
let offset = ref(20)

const workStatusReports = ref([]);
const members = ref([]);
const member = ref(null);
const getMembers = async () => {
  const res = await fetchParties(`?company_id=${companyId.value}&with_link_user=${true}`);
  if (res.status) {
    members.value = res.data.map((item) => {
      let name = item.name
      if (item.linked_user) {
        name = `${name} (${item.linked_user.name})`
      }
      item.name = name
      return item;
    })
  }
}

const companyId = computed(() => {
  return route.params.companyId
})
const startDate = computed(() => {
  return route.query.start
})
const endDate = computed(() => {
  return route.query.end
})
const page = computed(() => {
  return route.query.page
})

function getQuery() {
  let query = '?company_id=' + companyId.value
  if (!startDate.value && !endDate.value) return query
  if (member.value) query += '&member_id=' + member.value
  if (props.userId) query += '&user_id=' + props.userId
  if (startDate.value) query += '&start_date=' + startDate.value
  if (endDate.value) query += '&end_date=' + endDate.value
  if (page.value) query += '&page=' + page.value
  query += '&offset=' + offset.value
  return query
}

function onClickGo() {
  fetchWorkStatusReport(getQuery()).then(({data, status}) => {
    if (!status) {
      workStatusReports.value = []
      return store.commit("resetPagination")
    }

    workStatusReports.value = data.data
    setPagination(data)
  })
}

function setPagination(data) {
  if (!data.data.length) return
  store.commit('setPaginationData', {
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

function onPageChange(page) {
  let routeQuery = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  onClickGo()
}

onMounted(() => {
  onClickGo()
  if (!props.userId){
    getMembers()
  }
})

</script>
