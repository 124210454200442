<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table custom-table-padding">
        <thead>
        <tr>
          <th>Date</th>
          <th>Client Name</th>
          <th>Project Name</th>
          <th>Activity / Task Name</th>
          <th>Working Hours</th>
          <th>Work Station</th>
          <th>Conveyance Bill</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in tableItems" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.full_name }}</td>
          <td>{{ item.project_name }}</td>
          <td>{{ item.full_description }}</td>
          <td>{{ item.hours }}</td>
          <td>{{ item.area_name }}</td>
          <td>{{ item.conveyance }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th colspan="4" class="text-center">Total</th>
          <th>{{sumHours()}}</th>
          <th colspan="2"></th>
        </tr>
        </tfoot>
      </table>
      <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  tableItems: {
    type: Array,
    default: []
  }
})

const sumHours = () => {
 return props.tableItems.reduce((total, i) => total + parseFloat(i.hours), 0);
}
</script>
